import "@/../css/style.css"
import { createInertiaApp } from "@inertiajs/react"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ReactNode, Suspense } from "react"
import { createRoot } from "react-dom/client"

import Layout from "./Layouts/Layout"
import LoadingBackdrop from "./Shared/components/LoadingBackdrop"
import "./bootstrap"
import "./tiptap.scss"

// import * as Sentry from "@sentry/react"

// Sentry.init({
//   dsn: "https://42bb32a352685790c82a7676bb232bf4@o4507295011045376.ingest.de.sentry.io/4507295019237456",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: import.meta.env.MIX_APP_ENV,
// })

createInertiaApp({
  resolve: name =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob("./Pages/**/*.tsx"),
    ).then(
      // @ts-expect-error TS(2339) FIXME: Property 'default' does not exist on type 'Promise... Remove this comment to see the full error message
      ({ default: page }) => {
        if (page.layout === undefined) {
          page.layout = (page: ReactNode) => <Layout>{page}</Layout>
        }
        return page
      },
    ),
  setup({ el, App, props }) {
    createRoot(el).render(
      <Suspense fallback={<LoadingBackdrop />}>
        <App {...props} />
      </Suspense>,
    )
  },
})

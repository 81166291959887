import { Inertia } from "@inertiajs/inertia"
import { useState } from "react"

import { Backdrop, CircularProgress } from "@mui/material"

const LoadingBackdrop = () => {
  const [loading, setLoading] = useState(false)
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined

  Inertia.on("start", () => {
    timeout = setTimeout(() => setLoading(true), 250)
  })

  Inertia.on("finish", () => {
    clearTimeout(timeout)
    setLoading(false)
  })
  return (
    <Backdrop
      sx={{ zIndex: "tooltip", color: "#fff" }}
      open={loading}
      data-testid="backdrop">
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default LoadingBackdrop

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"

export type FlashType = {
  error?: string
  success?: string
}

type FlashContext = {
  flashes: FlashType
  setFlashes: Dispatch<SetStateAction<FlashType>>
}

const FlashContext = createContext<FlashContext | null>(null)
export const useFlashContext = () => useContext(FlashContext)

type FlashContextProviderProps = {
  children?: ReactNode
}

const FlashContextProvider = ({ children }: FlashContextProviderProps) => {
  const [flashes, setFlashes] = useState<FlashType>({})

  return (
    <FlashContext.Provider value={{ flashes, setFlashes }}>
      {children}
    </FlashContext.Provider>
  )
}
export default FlashContextProvider

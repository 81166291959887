import { usePage } from "@inertiajs/react"

import { Box, List } from "@mui/material"

import { User } from "@/types"

import MenuLogo from "./Menu/Logo"
import MenuButtons from "./Menu/MenuButtons"
import MobileMenu from "./Menu/MobileMenu"
import UserWelcome from "./Menu/UserWelcome"

const Menu = () => {
  const user = usePage().props.user as User
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          p: 2,
          minWidth: 220,
        }}>
        <MenuLogo />
        <UserWelcome user={user} />
        <List sx={{ width: "100%", height: "100%" }}>
          <MenuButtons />
        </List>
      </Box>

      <MobileMenu />
    </>
  )
}
export default Menu

import { ReactNode, createContext, useContext, useState } from "react"

type TitleType = string | undefined

export type TitleContextType = {
  title: TitleType
  setTitle: (title?: TitleType) => void
}

const TitleContext = createContext<TitleContextType | null>(null)
export const useTitleContext = () => useContext(TitleContext)

type TitleContextProviderProps = {
  children?: ReactNode
}

const TitleContextProvider = ({ children }: TitleContextProviderProps) => {
  const [title, setTitle] = useState<TitleType>()

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}
export default TitleContextProvider

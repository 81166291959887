import logo from "@/../assets/logo.png"

import { Box } from "@mui/system"

const MenuLogo = () => (
  <Box display={"flex"} sx={{ borderBottom: 1, borderColor: "divider" }}>
    <img
      src={logo}
      style={{
        marginRight: "auto",
        marginLeft: "auto",
        width: "120px",
      }}
    />
  </Box>
)

export default MenuLogo
